import { Alert } from "@chakra-ui/react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

export default function ErrorBoundary({
  children,
  message = "Something went wrong",
}: any) {
  return (
    <SentryErrorBoundary fallback={<Alert status="error">{message}</Alert>}>
      {children}
    </SentryErrorBoundary>
  );
}
