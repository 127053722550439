import {
  Alert,
  Stack,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { getReadableDate } from "utils/date";
import { MdDelete } from "react-icons/md";
import { deleteAccount } from "../api";
import { Account } from "types";

type AccountsListProps = {
  accounts: Account[];
  update: any;
}
export default function AccountsList({ accounts, update }: AccountsListProps) {
  return accounts.length === 0 ? (
    <Alert status="info">No accounts found</Alert>
  ) : (
    <Stack width="fit-content">
      <Heading size="md">Accounts</Heading>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th isNumeric>Added</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {accounts.map((account: Account, index: number) => (
              <Tr key={`account-${index}`}>
                <Td>{account.username}</Td>
                <Td>{getReadableDate(account.createdAt)}</Td>
                <Td>
                  <RemoveAccount account={account} update={update} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

type RemoveAccountProps = {
  account: any;
  update: any;
};
function RemoveAccount({ account, update }: RemoveAccountProps) {
  const toast = useToast();
  const onClick = () => {
    const promise = deleteAccount({ accountId: account._id });
    promise.then(() => {
      update();
    });
    toast.promise(promise, {
      loading: { title: "Deleting account..." },
      success: { title: "Account deleted" },
      error: { title: "Something went wrong when deleting the account" },
    });
  };

  return (
    <Tooltip label={`Remove ${account.username}`}>
      <IconButton
        onClick={onClick}
        colorScheme="red"
        aria-label="Remove account"
        icon={<MdDelete size={20} />}
      />
    </Tooltip>
  );
}
