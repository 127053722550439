import { Suspense } from "react";
import { ErrorBoundary } from "@sentry/react";
// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  Spinner,
  Alert,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
// Layout components
import NavbarAdmin from "components/navbar/NavbarAdmin";
// import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Navigate, Outlet } from "react-router";
import tabs from "tabs";
import Cookies from "js-cookie";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const bgColor = useColorModeValue("gray.150", "navy.700");
  // #f4f7fe
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  if (!Cookies.get("token")) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <Flex direction="column">
      {/* <Navbar /> */}

      <Flex>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={tabs} {...rest} />
          <Flex
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%" }}
            // maxWidth={{
            //   base: "100%",
            //   xl: `calc( 100% - 250px )`,
            //   sm: "calc( 100% - 50px )",
            // }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
            backgroundColor={bgColor}
            flex={1}
          >
            <Portal>
              <Box>
                <NavbarAdmin
                  onOpen={onOpen}
                  logoText={"Blisss.ai"}
                  brandText={""}
                  secondary={false}
                  message={""}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
            <Flex padding={4} w="100%" flex={1}>
              <Flex
                direction="column"
                gap={4}
                minHeight="100vh"
                flex={1}
                w="100%"
                justify="space-between"
              >
                <ErrorBoundary
                  fallback={<Alert status="error">Something went wrong</Alert>}
                >
                  <Suspense fallback={<Spinner margin={4} />}>
                    <Outlet />
                  </Suspense>
                </ErrorBoundary>
                <Footer />
              </Flex>
            </Flex>
          </Flex>
        </SidebarContext.Provider>
      </Flex>
    </Flex>
  );
}
