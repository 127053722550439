import React, { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type TourStep = {
  content: React.ReactNode;
  locale?: any;
  placement?: string;
  target?: string;
  path?: string;
  data?: any;
  floaterProps?: any;
  spotlightPadding?: number;
  styles?: any;
  title?: string;
};

export default function Tour() {
  const [state, setState] = useState<any>({
    run: false,
    stepIndex: 0,
    tourActive: false,
  });
  const navigate = useNavigate();

  const steps: any = [
    {
      content: (
        <h2>Let's begin our journey with {process.env.REACT_APP_APP_NAME}!</h2>
      ),
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "center",
      target: "body",
      data: { path: "/admin/" },
    },
    {
      content: <h2>Click here to visit the landing page</h2>,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: "#logo",
      data: { path: "/admin/" },
    },
    {
      content: "Create a new organisation here",
      placement: "auto",
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#new-organisation",
      title: "New Organisation",
      data: { path: "/admin/organisations" },
    },
    {
      title: "WordPress sites",
      content: "Add your websites to publish blogs",
      placement: "auto",
      target: "#new-website",
      data: { path: "/admin/websites" },
    },
    {
      title: "Generating blogs",
      content: "Choose any topic and use AI to generate blog",
      placement: "auto",
      target: "#choose-topic",
      data: { path: "/admin/blogger" },
    },

    {
      title: "Settings",
      content: "Add your own OpenAI API key",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#openai-settings",
      data: { path: "/admin/settings" },
    },

    {
      title: "Settings",
      content: "You can always delete your account",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#delete-account",
      data: { path: "/admin/settings" },
    },

    {
      title: "Profile",
      content: "You can view information about your account here",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#profile",
      data: { path: "/admin/profile" },
    },

    {
      title: "Connections",
      content: "You can log into your social media or other accounts here",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#connections",
      data: { path: "/admin/connections" },
    },

    {
      title: "Dark Mode",
      content: "Switch between light and dark mode based on your preference",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#dark-mode",
      data: { path: "/admin/connections" },
    },

    {
      title: "Menu",
      content: "Click on this menu to log out of your account",
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "auto",
      target: "#menu",
      data: { path: "/admin/connections" },
    },

    {
      content: (
        <h2>
          You are ready to get started with {process.env.REACT_APP_APP_NAME}!
          Feel free to explore the app further and contact us if you have any
          questions.
        </h2>
      ),
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: "center",
      target: "body",
      data: { path: "/admin/" },
    },
  ];

  const handleCallback = (data: CallBackProps) => {
    const {
      action,
      index,
      type,
    } = data;
    // const isPreviousAction = action === "prev";
    const nextIndex = index + 1;
    const nextStep = steps[nextIndex];

    if (action === "close") {
      setState({ run: false });
      return;
    }
    if (type === "step:after") {
      if (index < steps.length - 1) {
        setState({ stepIndex: nextIndex, run: false });

        const interval = setInterval(() => {
          const nextTarget = nextStep?.target;
          const component = document.getElementById(
            (nextTarget as string)?.replace("#", "")
          );

          if (!((nextTarget as string)?.startsWith("#") && !component)) {
            setState({ run: true });
            clearInterval(interval);
          }
        }, 500);

        if (nextStep?.data?.path) {
          navigate(nextStep.data.path);
        }
      }

      if (index === steps.length - 1) {
        setState({ run: false });
      }
    }
  };

  return (
    <Box w="100%">
      <Joyride
        callback={handleCallback}
        continuous
        run={state.run}
        stepIndex={state.stepIndex}
        scrollToFirstStep
        showProgress
        steps={steps}
        hideBackButton={true}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "blue",
          },
        }}
      />
      <Button w="100%" onClick={() => setState({ stepIndex: 0, run: true })}>
        Start tour
      </Button>
    </Box>
  );
}