import {
  Stack,
  Heading,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  Divider,
  TabPanel,
  Badge,
  Flex,
} from "@chakra-ui/react";
import ScrapeWebPage from "./components/ScrapeWebPage";
import ScrapeWebsite from "./components/ScrapeWebsite";

export default function WebScraper() {
  const tabs = [
    {
      name: "Scrape webpage",
      component: <ScrapeWebPage />,
    },
    {
      name: "Scrape website",
      label: <Flex gap={2}>Scrape website<Badge>BETA</Badge></Flex>,  
      component: <ScrapeWebsite />,
    },
  ];
  return (
    <Stack>
      <Heading>Web Scraper</Heading>

      <Divider my={2} />

      <Tabs>
        <TabList>
          {tabs.map(({ name, label }) => (
            <Tab key={name}>{label ?? name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabs.map(({ name, label, component }) => (
            <TabPanel key={name}>{component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
