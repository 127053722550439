/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// Chakra imports
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  // FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import GoogleLogin from "./components/GoogleLogin";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Cookies from "js-cookie";
import authStore from "stores/authStore";
import { apiClient } from "utils/api";
import { googleLogin } from "./api";
import * as Sentry from "@sentry/react";

type Inputs = {
  username: string;
  password: string;
};

function setUserToken(token: string) {
  Cookies.set("token", token, {
    expires: parseInt(process.env.REACT_APP_JWT_EXPIRES),
  });
}

function setSentryUser({ email, name }: { email?: string; name?: string }) {
  Sentry.setUser({ name, email });
}

function SignIn() {
  // Chakra color mode
  const toast = useToast();
  const navigate = useNavigate();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const schema = yup
    .object({
      username: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();
  const resolver = yupResolver(schema);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver });

  const notification = () => {
    toast({
      title: "Logged in successfully",
      description: `Welcome to ${process.env.REACT_APP_APP_NAME}`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const login = (token: string) => {
    setUserToken(token);
    notification();
    navigate("/admin/websites");
  };

  const onError = () => {
    toast({
      title: "Error",
      description: "Unable to login.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    apiClient
      .post("/login", formData)
      .then((response) => {
        const { token, email, name } = response.data;
        login(token);
        setSentryUser({ email, name });
      })
      .catch(() => {
        onError();
      });
  };

  useEffect(() => {
    if (authStore.isLoggedIn) {
      navigate("/admin/websites");
    }
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <GoogleLogin
            onSuccess={(accessToken) => {
              googleLogin(accessToken)
                .then((res: any) => {
                  const { token, email, name } = res.data;
                  // console.log(token);
                  login(token);
                  setSentryUser({ email, name });
                })
                .catch(() => {
                  onError();
                });
            }}
            onError={onError}
          />
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              required={true}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@example.com"
              fontWeight="500"
              size="lg"
              {...register("username")}
            />
            {errors.username && (
              <Alert status="warning" marginBottom={5}>
                {errors.username.message}
              </Alert>
            )}
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
              mt={4}
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                required={true}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                {...register("password")}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <Alert status="warning">{errors.password.message}</Alert>
            )}
            <Flex justifyContent="space-between" align="center" margin={4}>
              <FormControl display="flex" alignItems="center" gap={2}>
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  // me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit(onSubmit)}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
