import { Flex, Heading, Image, Link } from "@chakra-ui/react";

export default function Logo() {
  return (
    <Link href="/" id="logo">
      <Flex gap={2} color="white">
        <Image src="/blisss-logo.png" w="40px" h="40px" borderRadius="50%" />
        <Heading>Blisss AI</Heading>
      </Flex>
    </Link>
  );
}
