import { Input, Stack, useToast, Flex, Button } from "@chakra-ui/react";
import CopyToClipboard from "components/CopyToClipboard";
import { useState } from "react";
import { scrapeWebPage } from "../api";
import { StructuredData } from "./StructuredData";

export default function ScrapeWebPage() {
  const toast = useToast();
  const [url, setUrl] = useState<string>();
  const [structuredData, setStructuredData] = useState<any>();

  const onSubmit = () => {
    const promise = scrapeWebPage(url);

    promise.then((res) => {
      const { structuredData } = res.data;
      setStructuredData(structuredData);
    });

    toast.promise(promise, {
      loading: { title: "Scraping web page..." },
      success: { title: "Web page scraped" },
      error: { title: "Something went wrong when scraping the web page." },
    });
  };
  return (
    <Stack>
      <Flex gap={4}>
        <Input
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          placeholder="Enter URL"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <Button onClick={onSubmit}>Submit</Button>
      </Flex>

      {structuredData && (
        <Stack>
          <CopyToClipboard
            text={JSON.stringify(structuredData)}
            label="Copy JSON"
          />
          <StructuredData data={structuredData} />
        </Stack>
      )}
    </Stack>
  );
}
