import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

type CopyToClipboardProps = {
  text: string;
  label?: string;
};
export default function CopyToClipboard({ text, label }: CopyToClipboardProps) {
  const [copied, setCopied] = useState(false);

  return (
    <Tooltip label={label}>
      <IconButton
        aria-label="Copy to clipboard"
        icon={copied ? <CheckIcon fontSize={20} /> : <CopyIcon fontSize={20} />}
        onClick={() => {
          navigator.clipboard.writeText(text);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
        size="sm"
        w="fit-content"
      />
    </Tooltip>
  );
}
