import { Flex, Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

type FormFooterProps = {
  activeStep: number;
  totalSteps: number;
  setActiveStep: (val: number) => void;
  goToNext: () => void;
  goToPrevious: () => void;
  onSubmit?: () => void;
  onReset?: () => void;
  validate?: string[];
  showSubmit?: boolean;
  skipText?: string;
  nextText?: string;
};
const FormFooter = ({
  setActiveStep,
  goToNext,
  goToPrevious,
  activeStep,
  validate,
  totalSteps,
  onSubmit,
  showSubmit = true,
  onReset,
  skipText = "Skip",
  nextText = "Next",
}: FormFooterProps) => {
  const { trigger, reset } = useFormContext<any>();

  return (
    <Flex direction="row" gap={2} justify="space-between">
      <Flex>
        <Button
          isDisabled={activeStep === 0}
          onClick={goToPrevious}
          width="fit-content"
        >
          Prev
        </Button>
        <Button
          isDisabled={activeStep >= totalSteps - 1}
          onClick={() => {
            if (validate) {
              trigger(validate).then((res) => {
                if (res) goToNext();
              });
            } else {
              goToNext();
            }
          }}
          width="fit-content"
        >
          {nextText}
        </Button>
        <Button
          isDisabled={activeStep >= totalSteps - 1 || validate?.length > 0}
          onClick={() => {
            goToNext();
          }}
          width="fit-content"
        >
          {skipText}
        </Button>
        <Button
          onClick={() => {
            reset();
            setActiveStep(1);
            if (onReset) {
              onReset();
            }
          }}
          width="fit-content"
        >
          Reset
        </Button>
      </Flex>
      {showSubmit && (
        <Button
          isDisabled={activeStep < totalSteps - 1 || !onSubmit}
          onClick={onSubmit}
          width="fit-content"
          colorScheme="blue"
        >
          Submit
        </Button>
      )}
    </Flex>
  );
};

export default FormFooter;
