import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import Cookies from "js-cookie";

class AuthStore {
  username: string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: `${process.env.REACT_APP_APP_NAME}AuthStore`.replaceAll(" ", ""),
      properties: ["username"],
      storage: window.localStorage,
      expireIn: 1000 * 60 * 60 * 24,
    });
  }

  setUsername(username: string) {
    this.username = username;
  };

  get isLoggedIn() {
    return !!Cookies.get("token");
  };

  logOut() {
    this.username = null;
    Cookies.remove("token");
    window.location.href = "/sign-in";
  };
}

const authStore = new AuthStore();

export default authStore;
