/* eslint-disable */

import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { colors } from "theme/styles";

export function SidebarLinks({
  routes,
  collapsed,
}: {
  routes: RoutesType[];
  collapsed?: boolean;
}) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("white", "gray.700");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let textColor = useColorModeValue("white", "white");
  let activeIcon = useColorModeValue(colors.blue, "white");
  let brandColor = useColorModeValue(colors.purple, "brand.400");

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <NavLink
            key={index}
            to={route.layout + route.path}
            title={route.name}
            style={{ width: "fit-content" }}
          >
            {route.icon ? (
              <Box w="fit-content">
                <HStack
                  w="fit-content"
                  //   spacing={
                  //     activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  //   }
                >
                  <Flex w="fit-content" gap={4}>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                    <Box
                      m="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me={collapsed ? 0 : 18}
                    >
                      {route.icon}
                    </Box>
                    {!collapsed && (
                      <Text
                        m="auto"
                        casing="uppercase"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                        w="fit-content"
                      >
                        {route.name}
                      </Text>
                    )}
                  </Flex>
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  //   py="5px"
                  //   ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return <Stack w="fit-content">{createLinks(routes)}</Stack>;
}

export default SidebarLinks;
