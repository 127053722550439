import { Heading, Stack } from "@chakra-ui/react";
import ComingSoon from "components/announcements/ComingSoon";

function Analytics() {
  return (
    <Stack>
      <Heading>Analytics</Heading>
      <ComingSoon />
    </Stack>
  );
}

export default Analytics;
