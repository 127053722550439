import { Button, Icon, useColorModeValue } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";

type GoogleLoginProps = {
  onSuccess: (code: string) => void;
  onError: (error: any) => void;
};
export default function GoogleLogin({ onSuccess, onError }: GoogleLoginProps) {
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const scopes = [
    "https://www.googleapis.com/auth/gmail.compose",
    "https://www.googleapis.com/auth/gmail.send", 
    "https://www.googleapis.com/auth/gmail.readonly" ,
    "https://www.googleapis.com/auth/pubsub", 
    "https://www.googleapis.com/auth/gmail.modify"
  ];

  const login = useGoogleLogin({
    onSuccess: ({ code }) => onSuccess(code),
    onError: (error) => onError(error),
    flow: 'auth-code',
    scope: scopes.join(' '),
  });

  return (
    <Button
      fontSize="sm"
      me="0px"
      mb="26px"
      py="15px"
      h="50px"
      borderRadius="16px"
      bg={googleBg}
      color={googleText}
      fontWeight="500"
      _hover={googleHover}
      _active={googleActive}
      _focus={googleActive}
      onClick={() => login()}
      disabled={!process.env.REACT_APP_GOOGLE_AUTH}
    >
      <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
      Sign in with Google
    </Button>
  );
}
