import {
  Input,
  Stack,
  useToast,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { scrapeLinks, scrapeWebPage } from "../api";
import { StructuredData } from "./StructuredData";

export default function ScrapeWebsite() {
  const toast = useToast();
  const [url, setUrl] = useState<string>();
  const [links, setLinks] = useState<string[]>();
  const [data, setData] = useState<any>();

  const onSubmit = () => {
    const promise = scrapeLinks(url);

    promise.then((res) => {
      const { links } = res.data;
      setLinks(links);
    });

    toast.promise(promise, {
      loading: { title: "Searching links on website..." },
      success: { title: "Found links" },
      error: { title: "Something went wrong when scraping the website." },
    });
  };

  useEffect(() => {
    const data = {};
    if (links) {
      for (let link of links) {
        data[link] = 'Loading...';
        const promise = scrapeWebPage(link);

        promise.then((res) => {
          const { structuredData } = res.data;
          setData((data: any) => ({ ...data, [link]: structuredData }));
        });
      }
      setData(data);
    }
  }, [links]);

  return (
    <Stack>
      <Flex gap={4}>
        <Input
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          placeholder="Enter URL"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <Button onClick={onSubmit}>Submit</Button>
      </Flex>
      {data && <StructuredData data={data} />}
    </Stack>
  );
}
