import {
  FormControl,
  FormLabel,
  Input,
  Alert,
  useColorModeValue,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

type FormInputProps = {
  field: string;
  label?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  helperText?: React.ReactNode;
  textarea?: boolean;
};
const FormInput = ({
  field,
  label,
  type = "text",
  required = true,
  placeholder = "",
  defaultValue = "",
  helperText = "",
  textarea = false,
}: FormInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        mb="8px"
      >
        {label ?? field}
      </FormLabel>
      {textarea ? (
        <Textarea
          isRequired={required}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          placeholder={placeholder}
          fontWeight="500"
          size="lg"
          {...register(field)}
          defaultValue={defaultValue}
          background="none"
          border="1px solid gray" 
        />
      ) : (
        <Input
          isRequired={required}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type={type}
          placeholder={placeholder}
          fontWeight="500"
          size="lg"
          {...register(field)}
          defaultValue={defaultValue}
        />
      )}
      <FormHelperText>{helperText}</FormHelperText>
      {errors[field] && (
        <Alert status="warning" marginBottom={5}>
          {errors[field].message as string}
        </Alert>
      )}
    </FormControl>
  );
};

export default FormInput;
