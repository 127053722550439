import { Suspense as ReactSuspense } from "react";
import { Spinner } from "@chakra-ui/react";

export default function Suspense({
  children,
  message = "Something went wrong",
}: any) {
  return (
    <ReactSuspense fallback={<Spinner />}>
      {children}
    </ReactSuspense>
  );
}
