import { Box, Heading, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { ListItem, UnorderedList } from "@chakra-ui/react";

export default function PrivacyPolicy() {
  const data: any = [
    {
      title: "Introduction",
      points: [
        "We only ask for personal information when we truly need it to provide a service to you." +
          "We collect it by fair and lawful means, with your knowledge and consent." +
          "We also let you know why we're collecting it and how it will be used.",
      ],
    },
    {
      title: "Information we collect",
      points: [
        "The app collects information from your Google profile such as email, name and profile picture.",
        "The app will also access your emails for the Mail client",
        "The app will also get notifications for emails received in your Gmail inbox.",
      ],
    },
    {
      title: "Why we collect data",
      points: [
        "The information enables us to provide you with a personalised experience and deliver our services more effectively.",
      ],
    },
    {
      title: "Sharing your information",
      points: [
        "We do not share your personal information with companies, organisations or individuals without your permission.",
      ],
    },
    {
      title: "Keeping your information secure",
      points: [
        "We try hard to keep your data safe by preventing unauthorised access to our databases and avoiding sensitive data in transit.",
      ],
    },
    {
      title: "Deleting your information",
      points: ["You can delete your account and data from the Settings page."],
    },
    {
      title: "Retaining your information",
      points: [
        "We only retain collected information for as long as necessary to provide " +
          "you with your requested service. What data we store, we'll protect " +
          "within commercially acceptable means to prevent loss and theft, as well " +
          "as unauthorized access, disclosure, copying, use or modification.",
        "Your data will not be used to train any generalized AI models by us or any third-party APIs.",
      ],
    },
    {
      title: "About this policy",
      points: [
        "Your continued use of our website will be regarded as acceptance of our " +
          "practices around privacy and personal information. If you have any " +
          "questions about how we handle user data and personal information, feel" +
          "free to contact us.",
        "You are free to refuse our request for your personal information, with " +
          "the understanding that we may be unable to provide you with some of your " +
          "desired services.",
        "Our website may link to external sites that are not operated by us." +
          "Please be aware that we have no control over the content and practices " +
          "of these sites, and cannot accept responsibility or liability for their " +
          "respective privacy policies.",
      ],
    },
  ];
  return (
    <Box maxW="800px" mx="auto" px={8} py={12}>
      <Heading as="h1" mb={6}>
        Privacy Policy
      </Heading>
      <Text mb={4}>
        Your privacy is important to us. It is {process.env.REACT_APP_APP_NAME}
        's policy to respect your privacy regarding any information we may
        collect from you across our website and other sites we own and operate.
      </Text>

      <Accordion
        allowMultiple
        defaultIndex={data.map((_: any, i: number) => i)}
      >
        {data.map(({ title, points }: any) => (
          <AccordionItem key={title}>
            <Box id={title.toLowerCase().replaceAll(" ", "-")}>
              <AccordionButton>
                <Text fontWeight="bold" as="span" flex="1" textAlign="left">
                  {title}
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  {points.map((line: string) => (
                    <ListItem>{line}</ListItem>
                  ))}
                </UnorderedList>
              </AccordionPanel>
            </Box>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}
