import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

type ModalProps = {
  title?: string;
  body: any;
  footer?: any;
  size?: string;
  onClose?: () => void;
  isOpen?: boolean;
};
export default function Modal({
  title = "",
  body,
  footer = null,
  isOpen = false,
  onClose = () => {},
}: ModalProps) {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="fit-content">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
