import { Suspense } from "react";
import { ColorModeProvider } from "@chakra-ui/react";
import "./assets/css/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import * as Sentry from "@sentry/react";
import { clarity } from "react-microsoft-clarity";
import { routes } from "./routes";
import Tour from "components/Tour";

// Initialise Microsoft Clarity project
if (process.env.NODE_ENV === "production") {
  clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID);

  Sentry.init({
    dsn: "https://f9d2f72ff8130f3209e12a0809abdfcf@o4507254148825088.ingest.de.sentry.io/4507254155116624",
    integrations: [
      Sentry.feedbackIntegration({
        triggerLabel: "Feedback",
        formTitle: "Feedback",
        submitButtonLabel: "Send feedback",
        colorScheme: "system",
        messagePlaceholder: "Let us know about any bugs or improvements",
        successMessageText: "Thanks a lot for your feedback!",
      }),
    ],
  });
}

const App = () => {
  const router = createBrowserRouter(routes);

  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </ColorModeProvider>
    </ChakraProvider>
  );
};

export default App;
