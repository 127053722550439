import { useToast } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import PlatformPage from "./PlatformPage";
import { addInstagramAccount } from "../api";
import { useSWRConfig } from "swr";
import { LoginSocialInstagram, IResolveParams } from "reactjs-social-login";

// CUSTOMIZE ANY UI BUTTON
import { InstagramLoginButton } from "react-social-login-buttons";

export default function Instagram() {
  return (
    <PlatformPage
      platform="instagram"
      name="Instagram"
      icon={<FaInstagram />}
      signIn={<SignIn />}
      // postEditor={
      //   <SocialMediaPost
      //     onSubmit={onSubmit}
      //     platform="instagram"
      //     accountRequired={false}
      //     showUrl={true}
      //   />
      // }
    />
  );
}

function SignIn() {
  const { mutate } = useSWRConfig();

  const toast = useToast();

  const responseInstagram = (code: string) => {
    const promise = addInstagramAccount(code);

    promise.then(() => {
      mutate("instagram-accounts");
    });

    toast.promise(promise, {
      loading: { title: "Connecting account..." },
      success: { title: "Account connected" },
      error: {
        title: "Something went wrong when connecting the account.",
      },
    });
  };

  const scopes = [
    "business_basic",
    "business_content_publish",
  ];

  return (
    <LoginSocialInstagram
      isOnlyGetCode
      client_id={process.env.REACT_APP_INSTAGRAM_APP_ID}
      client_secret=""
      redirect_uri={window.location.href}
      onResolve={({ data: { code } }: IResolveParams) => {
        responseInstagram(code);
      }}
      onReject={(err: any) => {
        // console.log(err);
        toast({
          title: "Something went wrong when connecting the account.",
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }}
      scope={scopes.join(",")}
    >
      <InstagramLoginButton style={{ width: "fit-content" }} />
    </LoginSocialInstagram>
  );
}
