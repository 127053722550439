import {
  Card,
  CardBody,
  Heading,
  Link,
  Flex,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";
import { Article } from "types";
import { getReadableDate } from "utils/date";

export default function ArticleDetails({
  title,
  description,
  url,
  imageUrl,
  publishedAt,
}: Article) {
  return (
    <Link href={url} isExternal>
      <Card
        border="1px solid lightgray"
        _hover={{
          backgroundColor: "#f0f0f0",
          transition: "0.5s",
          transform: "scale(0.99)",
        }}
        height="100%"
        transition="0.5s"
      >
        <CardBody>
          <Flex align="center" gap={4}>
            {imageUrl && (
              <Image
                src={imageUrl}
                height="auto"
                width="25%"
                borderRadius={5}
              />
            )}
            <Stack>
              <Heading size="md">{title}</Heading>
              <Text size="sm" color="gray">{getReadableDate(publishedAt)}</Text>
              <Text>{description}</Text>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
    </Link>
  );
}
