import { Stack, Select, Text } from "@chakra-ui/react";
import ErrorBoundary from "components/ErrorBoundary";
import Suspense from "components/Suspense";

import { useForm, useFormContext, FormProvider } from "react-hook-form";
import useSWR from "swr";
import { fetcher } from "utils/api";
import ArticlesList from "./ArticlesList";

export default function TopHeadlines() {
  const form = useForm();

  return (
    <FormProvider {...form}>
      <Stack>
        <SelectCategory />

        <ErrorBoundary>
          <Suspense>
            <Headlines />
          </Suspense>
        </ErrorBoundary>
      </Stack>
    </FormProvider>
  );
}

function SelectCategory() {
  const categories = [
    { label: "Business", value: "business" },
    { label: "Entertainment", value: "entertainment" },
    { label: "General", value: "general" },
    { label: "Health", value: "health" },
    { label: "Science", value: "science" },
    { label: "Sports", value: "sports" },
    { label: "Technology", value: "technology" },
  ];

  const form = useFormContext();

  const category = form.watch("category", "");

  return (
    <Stack>
      <Text>Category</Text>
      <Select
        placeholder="Not selected"
        value={category}
        onChange={(e: any) => form.setValue("category", e.target.value)}
      >
        {categories.map(
          ({ label, value }: { label: string; value: string }) => (
            <option key={value} value={value}>
              {label}
            </option>
          )
        )}
      </Select>
    </Stack>
  );
}

function Headlines() {
  const form = useFormContext();

  const category = form.watch("category");

  const shouldFetch = category;
  const key = shouldFetch ? `top-headlines-${category}` : null;

  const { data } = useSWR(
    key,
    () =>
      category === "Not selected"
        ? null
        : fetcher(`/news/top-headlines?category=${category}`),
    {
      suspense: shouldFetch,
    }
  );

  if (!data?.articles) {
    return <></>;
  }

  const { articles } = data;

  return <ArticlesList articles={articles} />;
}
