import { Badge, Box } from "@chakra-ui/react";

function ComingSoon() {
  return (
    <Box>
      <Badge colorScheme="orange" fontSize={20}>Coming soon...</Badge>
    </Box>
  );
}

export default ComingSoon;
