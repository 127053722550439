/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
// Custom components
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Flex,
  Text,
  useColorModeValue,
  // Stack,
  useToast,
} from "@chakra-ui/react";
import { fetcher } from "utils/api";
import useSWR from "swr";
import { UserProfile } from "types";

type ProfileProps = {
  collapsed?: boolean;
};
export default function Profile({ collapsed = false }: ProfileProps) {
  const toast = useToast();
  const { data, error } = useSWR("/users/me/profile", fetcher, {
    suspense: true,
  });
  const profile: UserProfile = data;
  const { name, firstName, pictureUrl } = profile;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("white", "white");
  const borderColor = useColorModeValue("white", "white");

  if (error) {
    toast({
      title: "Unable to fetch profile",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  return (
    <NavLink to="/admin/profile">
      <Flex gap={2} align="center" mx="auto">
        <Avatar
          src={pictureUrl}
          w="40px"
          h="40px"
          borderColor={borderColor}
          p={1}
        />
        {!collapsed && (
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            {firstName ?? name ?? "You"}
          </Text>
        )}
      </Flex>
    </NavLink>
  );
}
