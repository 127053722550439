import useSWR from "swr";
import { fetcher } from "utils/api";
import MailEditor from "./MailEditor";

export default function ComposeMail() {
  const { data: profile } = useSWR("/users/me/profile", fetcher, {
    suspense: true,
  });
  return <MailEditor selectEditor={true} message={{ from: profile.email }} />;
}
