import { FacebookResponse } from "types";
import { apiClient } from "utils/api";

export async function getSocialMediaAccounts({
  platform,
}: {
  platform: string;
}) {
  const res = await apiClient.get(`/accounts?platform=${platform}`);
  return res.data;
}

export function getTwitterAccounts() {
  return getSocialMediaAccounts({ platform: "x" });
}

export function getLinkedInAccounts() {
  return getSocialMediaAccounts({ platform: "linkedin" });
}

export function addLinkedInAccount({ code }: { code: string }) {
  return apiClient.post(
    `/linkedin/access-token?code=${encodeURIComponent(code)}`
  );
}

export function addFacebookAccount(response: FacebookResponse) {
  return apiClient.post("/facebook/accounts", response);
}

export function addInstagramAccount(code: string) {
  return apiClient.post(`/instagram/accounts?code=${encodeURIComponent(code)}`);
}


export function deleteAccount({ accountId }: { accountId: string }) {
  return apiClient.delete(`/accounts/${accountId}`);
}

export async function getPages({
  platform,
  username,
}: {
  platform: string;
  username: string;
}) {
  const res = await apiClient.get(`/${platform}/pages?username=${username}`);
  return res.data;
}
