import {
  Avatar, Flex,
  Stack,
  Tag,
  Text
} from "@chakra-ui/react";
import { Message } from "types";
import { getReadableDate } from "utils/date";
import { Letter } from "react-letter";


export function EmailHeader({ message }: { message: Message; }) {
  return (
    <Stack width="100%" align="flex-start">
      <Stack align="flex-start">
        <Flex gap={4} align="middle">
          <Avatar />
          <Tag height="fit-content" margin="auto">
            <Text>
              <b>{message.from}</b>
            </Text>
          </Tag>
          <Text margin="auto">{getReadableDate(message.date)}</Text>
        </Flex>
        <Text textAlign={"left"} color="gray">
          <Letter html={message.snippet} />
        </Text>
      </Stack>
    </Stack>
  );
}
