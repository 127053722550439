import { useContext } from "react";
// chakra imports
import {
  // Box,
  Flex,
  // IconButton,
  Stack,
} from "@chakra-ui/react";
//   Custom components
import Links from "components/sidebar/components/Links";
// import SidebarCard from 'components/sidebar/components/SidebarCard';
// import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { SidebarContext } from "contexts/SidebarContext";
import Profile from "./Profile";
import ErrorBoundary from "components/ErrorBoundary";
import Suspense from "components/Suspense";
import Logo from "components/navbar/Logo";
import Tour from "components/Tour";
// FUNCTIONS

function SidebarContent({ routes }: { routes: RoutesType[] }) {
  // SIDEBAR
  const {
    toggleSidebar,
    // setToggleSidebar
  } = useContext(SidebarContext);

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      align="center"
      gap={4}
      justify="space-between"
    >
      <Stack gap={4}>
        <Logo />

        {/* <Box display={{ sm: "none", xl: "block" }}>
          <IconButton
            w="fit-content"
            aria-label="collapse"
            icon={toggleSidebar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            onClick={() => setToggleSidebar(!toggleSidebar)}
          />
        </Box> */}

        <Links routes={routes} collapsed={toggleSidebar} />
      </Stack>

      <Stack w="100%" align="center">
        <Tour />


        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Profile collapsed={toggleSidebar} />
          </Suspense>
        </ErrorBoundary>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
