import { useState } from "react";
import JoditEditor from "jodit-react";
import { useMemo, useRef } from "react";
import "./editor.css";

type EditorProps = {
  content?: string;
  onUpdate?: (content: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const Editor = ({
  content: defaultContent = "",
  onUpdate = () => {},
  placeholder = "Start editing...",
  disabled = false,
}: EditorProps) => {
  const editor = useRef(null);
  const [content, setContent] = useState<string>(defaultContent);

  const config = useMemo(
    () => ({
      readonly: disabled,
      placeholder,
    }),
    [placeholder, disabled]
  );

  return (
    <JoditEditor
      // key={content}
      ref={editor}
      value={content}
      config={config}
      onBlur={(newContent: string) => {
        onUpdate(newContent);
      }} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent: string) => {
        setContent(newContent);
      }}
    />
  );
};

export default Editor;
