import { Alert, Stack } from "@chakra-ui/react";
import { Article } from "types";
import ArticleDetails from "./ArticleDetails";

export default function ArticlesList({ articles }: { articles: Article[]; }) {
  if (articles?.length === 0) {
    return <Alert>No articles found</Alert>
  }
  return (
    <Stack>
      {articles?.map((article: Article, index: number) => (
        <ArticleDetails key={`article-${index}`} {...article} />
      ))}
    </Stack>
  );
}
