import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Text,
  Flex,
  Heading,
  Link,
  Stack,
  Tag,
  Icon,
  Divider,
} from "@chakra-ui/react";
import Suspense from "components/Suspense";
import { IoMdCalendar } from "react-icons/io";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "utils/api";
import { getReadableDate } from "utils/date";
import { CgOrganisation } from "react-icons/cg";
import DeleteOrganisation from "./DeleteOrganisation";

export default function OrganisationPage() {
  const { organisationId } = useParams();

  const { data: organisation } = useSWR(
    `organisation-${organisationId}`,
    () => fetcher(`/organisations/${organisationId}`),
    {
      suspense: true,
      revalidateOnMount: false,
    }
  );

  const { name, websiteUrl, summary, createdAt, businessObjectives } = organisation;

  const panels = [
    {
      name: "Summary",
      content: <Summary text={summary} />,
    },
    {
      name: "Business Objectives",
      content: <BusinessObjectives values={businessObjectives} />,
    },
  ];
  return (
    <Stack>
      <Flex align="center" gap={4} marginY={4}>
        <CgOrganisation fontSize={35} />
        <Heading>{name}</Heading>
        <Link href={websiteUrl} isExternal>
          <Tag>
            {websiteUrl}
            <ExternalLinkIcon marginLeft={2} fontSize={18} />
          </Tag>
        </Link>
        <Tag>
          <Icon as={IoMdCalendar} marginRight={2} fontSize={20} />
          {getReadableDate(createdAt)}
        </Tag>
      </Flex>

      <Accordion allowToggle allowMultiple defaultIndex={[0]}>
        {panels.map(({ name, content }) => (
          <AccordionItem key={name}>
            <AccordionButton>
              <Flex gap={4}>
                <Heading size="md">{name}</Heading>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Suspense>{content}</Suspense>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Divider marginY={4} />
      <DeleteOrganisation organisationId={organisationId} name={name} />
    </Stack>
  );
}

function Summary({ text }: any) {
  return (
    <Card padding={4} style={{ whiteSpace: "pre-wrap" }}>
      <Text>{text}</Text>
    </Card>
  );
}

function BusinessObjectives({ values }: { values: string[] }) {
  return (
    <Flex gap={2}>
      {values.map((value) => (
        <Tag key={value}>{value}</Tag>
      ))}
    </Flex>
  );
}
