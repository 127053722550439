/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, Heading, Alert, Stack } from "@chakra-ui/react";
import AddOrganisation from "./components/AddOrganisation";
import { ErrorBoundary } from "@sentry/react";
import OrganisationsList from "./components/OrganisationsList";
import Suspense from "components/Suspense";

export default function Organisations() {

  return (
    <Stack>
      <Flex gap={8} alignItems="center">
        <Heading>Organisations</Heading>
        <AddOrganisation />
      </Flex>
      <ErrorBoundary
        fallback={<Alert status="error">Something went wrong</Alert>}
      >
        <Suspense>
          <OrganisationsList />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
}
