import { Stack, Flex, Heading, Divider } from "@chakra-ui/layout";
import { FaRegNewspaper } from "react-icons/fa";
import SearchArticles from "./components/SearchArticles";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import RecommendedArticles from "./components/RecommendedArticles";
import TopHeadlines from "./components/TopHeadlines";

export default function News() {
  const tabs = [
    {
      name: "Search",
      component: <SearchArticles />,
    },
    {
      name: "Recommended",
      component: <RecommendedArticles />,
    },
    {
      name: "Top headlines",
      component: <TopHeadlines />,
    },
  ];
  return (
    <Stack>
      <Flex align="center" gap={4}>
        <FaRegNewspaper fontSize={35} />
        <Heading>News</Heading>
      </Flex>
      <Divider />

      <Tabs>
        <TabList>
          {tabs.map(({ name }) => (
            <Tab key={name}>{name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabs.map(({ name, component }) => (
            <TabPanel key={name}>{component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
