import { lazy } from "react";
import { Icon } from "@chakra-ui/react";
import { MdPerson, MdLock, MdAnalytics, MdMailOutline } from "react-icons/md";
import { TbWorldSearch } from "react-icons/tb";
import { FaPenClip } from "react-icons/fa6";
import { CgWebsite, CgOrganisation } from "react-icons/cg";
import { IoSettingsSharp } from "react-icons/io5";
import { BiScan } from "react-icons/bi";

// import MainDashboard from "views/admin/default";
import Analytics from "views/admin/analytics";
import Connections from "views/admin/connections";
import { FaRegNewspaper } from "react-icons/fa";
const Mailer = lazy(() => import("views/admin/mailer"));
const SignInCentered = lazy(() => import("views/auth/SignIn"));
// Admin Imports
const Websites = lazy(() => import("views/admin/websites"));
const Organisations = lazy(() => import("views/admin/organisations"));
const Profile = lazy(() => import("views/admin/profile"));
const ContentGenerator = lazy(() => import("views/admin/content-generator"));
const WebScraper = lazy(() => import("views/admin/web-scraper"));
// Auth Imports

const tabs = [
  // {
  //   name: "Dashboard",
  //   layout: "/admin",
  //   path: "/dashboard",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: MainDashboard,
  // },
  {
    name: "Organisations",
    layout: "/admin",
    path: "/organisations",
    icon: (
      <Icon as={CgOrganisation} width="20px" height="20px" color="inherit" />
    ),
    component: Organisations,
    secondary: true,
  },
  {
    name: "Websites",
    layout: "/admin",
    path: "/websites",
    icon: <Icon as={CgWebsite} width="20px" height="20px" color="inherit" />,
    component: Websites,
    secondary: true,
  },
  {
    name: "Blogger",
    layout: "/admin",
    icon: <Icon as={FaPenClip} width="20px" height="20px" color="inherit" />,
    path: "/blogger",
    component: ContentGenerator,
  },
  {
    name: "Connections",
    layout: "/admin",
    path: "/connections",
    icon: (
      <Icon as={TbWorldSearch} width="20px" height="20px" color="inherit" />
    ),
    component: Connections,
  },
  {
    name: "Analytics",
    layout: "/admin",
    path: "/analytics",
    icon: <Icon as={MdAnalytics} width="20px" height="20px" color="inherit" />,
    component: Analytics,
  },
  {
    name: "Mail",
    layout: "/admin",
    path: "/mail",
    icon: (
      <Icon as={MdMailOutline} width="20px" height="20px" color="inherit" />
    ),
    component: Mailer,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: (
      <Icon as={IoSettingsSharp} width="20px" height="20px" color="inherit" />
    ),
    component: SignInCentered,
  },

  {
    name: "News",
    layout: "/admin",
    path: "/news",
    icon: (
      <Icon as={FaRegNewspaper} width="20px" height="20px" color="inherit" />
    ),
    component: SignInCentered,
  },
  {
    name: "Web Scraper",
    layout: "/admin",
    path: "/web-scraper",
    icon: (
      <Icon as={BiScan} width="20px" height="20px" color="inherit" />
    ),
    component: WebScraper,
  },

  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: RTL,
  // },
];

export default tabs;
