import {
  Heading,
  Input,
  Flex,
  Divider,
  Stack,
  useToast,
  Button,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Article } from "types";
import { searchArticles } from "../api";
import ArticlesList from "./ArticlesList";

type SearchArticlesProps = {
  defaultKeywords?: string;
};
export default function SearchArticles({
  defaultKeywords,
}: SearchArticlesProps) {
  const toast = useToast();

  const [query, setQuery] = useState<string>();
  const [searchedQuery, setSearchedQuery] = useState<string>();
  const [articles, setArticles] = useState<Article[]>();

  const onSearch = useCallback(() => {
    setSearchedQuery(query);
    const promise = searchArticles(query);

    promise.then(({ articles }: { articles: Article[] }) => {
      setArticles(articles);
    });

    toast.promise(promise, {
      loading: { title: "Searching articles..." },
      success: { title: "Search successful" },
      error: { title: "Search failed" },
    });
  }, [query, toast]);

  useEffect(() => {
    setQuery(defaultKeywords);
  }, [defaultKeywords]);

  return (
    <Stack>
      <Heading size="md">Search Articles</Heading>
      <Flex gap={4}>
        <Input
          placeholder="Keywords"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onSearch();
            }
          }}
          maxLength={100}
          defaultValue={defaultKeywords}
        />
        <Button onClick={onSearch}>Search</Button>
      </Flex>
      {searchedQuery && (
        <Text>
          Showing results for <b>{searchedQuery}</b>
        </Text>
      )}
      {articles?.length > 0 && <Divider />}
      {query && <ArticlesList articles={articles} />}
    </Stack>
  );
}
