import { useCallback, useEffect } from "react";
import { Stack, Flex, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Message } from "types";
import { emailReply } from "../api";
import { FaReply, FaReplyAll } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import MailEditor from "./MailEditor";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "utils/api";

type ReplyMailProps = {
  originalMessage: Message;
  autoGenerateReply?: boolean;
};
export default function ReplyMail({
  originalMessage,
  autoGenerateReply = false,
}: ReplyMailProps) {
  const { mutate } = useSWRConfig();

  const { data: profile } = useSWR("/users/me/profile", fetcher, {
    suspense: true,
  });

  const { from, cc, to } = originalMessage;

  const toast = useToast();
  const [message, setMessage] = useState<any>({
    from: profile.email,
    body: originalMessage.reply,
  });
  const [show, setShow] = useState<boolean>(false);

  const [generatingReply, setGeneratingReply] = useState<boolean>(false);

  const onGenerate = useCallback(() => {
    const promise = emailReply(originalMessage);

    setGeneratingReply(true);

    promise.then(({ reply }: { reply: string }) => {
      setMessage((message: any) => ({ ...message, body: reply }));
      setGeneratingReply(false);
    });

    toast.promise(promise, {
      loading: { title: "Generating reply..." },
      success: { title: "Reply generated!" },
      error: { title: "Error fetching reply" },
    });
  }, [originalMessage, toast]);

  const onReply = useCallback(() => {
    setMessage((message: any) => ({
      ...message,
      to: originalMessage.from,
      replyTo: originalMessage,
      subject: `Re: ${originalMessage.subject}`,
    }));
    setShow(true);

    if (!message.body && !generatingReply) {
      onGenerate();
    }
  }, [generatingReply, message.body, onGenerate, originalMessage]);

  useEffect(() => {
    if (autoGenerateReply) {
      onReply();
    }
  }, [autoGenerateReply, message.body, onGenerate, onReply]);

  return (
    <Stack>
      <Flex gap={2}>
        {show && (
          <Button leftIcon={<MdCancel />} onClick={() => setShow(false)}>
            Cancel
          </Button>
        )}
        <Button onClick={onReply} leftIcon={<FaReply />}>
          Reply
        </Button>
        <Button
          onClick={() => {
            setShow(true);

            if (!message.body && !generatingReply) {
              onGenerate();
            }

            setMessage((message: any) => ({
              ...message,
              to: [from, cc, to].filter((value: string) => value).join(", "),
              replyTo: originalMessage,
              subject: `${
                !originalMessage.subject.startsWith("Re:") ? "Re: " : ""
              }${originalMessage.subject}`,
            }));
          }}
          leftIcon={<FaReplyAll />}
        >
          Reply all
        </Button>
        <Button
          onClick={() => {
            setShow(true);
            setMessage((message: any) => ({
              ...originalMessage,
              from: message?.from ?? "",
              to: "",
              body: `<br/>---------- Forwarded message ---------<br/>${originalMessage.body}`,
              subject: `${
                !originalMessage.subject.startsWith("Fwd:") ? "Fwd: " : ""
              }${originalMessage.subject}`,
            }));
          }}
          leftIcon={<RiShareForwardFill />}
        >
          Forward
        </Button>
      </Flex>
      {show && (
        <MailEditor
          disabled={generatingReply}
          message={message}
          onSend={() => {
            mutate(`/mails/threads/${originalMessage.threadId}/messages`);
            setShow(false);
          }}
        />
      )}
    </Stack>
  );
}
