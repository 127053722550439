import { apiClient } from "utils/api";

export async function websiteSummary({ url }: { url: string }) {
  const res = await apiClient.get(`/organisations/website-summary`, {
    params: { url },
  });
  return res.data;
}


export function deleteOrganisation({ organisationId }: { organisationId: string }) {
  return  apiClient.delete(`/organisations/${organisationId}`);
}
