import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { useSWRConfig } from "swr";
import OrganisationForm from "./OrganisationForm";

export default function AddOrganisation() {
  const { mutate } = useSWRConfig();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button id="new-organisation" onClick={onOpen} leftIcon={<MdAdd />}>
        New organisation
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add organisation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrganisationForm
              onDone={() => {
                setTimeout(() => mutate('organisations'), 500);
                onClose();
                toast({
                  title: "Organisation added.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};