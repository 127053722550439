import { Message, SendEmail } from "types";
import { apiClient } from "utils/api";

export function sendEmail(email: SendEmail) {
  return apiClient.post("/mails", email);
}

export async function emailReply(message: Message) {
  const res = await apiClient.post("/mails/generate-reply", message);
  return res.data;
}

export function threadSummary(threadId: string) {
  return apiClient.get(`/mails/threads/${threadId}/summary`);
}

export function threadSnippet(threadId: string) {
  return apiClient.get(`/mails/threads/${threadId}/snippet`);
}

export function messageSnippet(messageId: string) {
  return apiClient.get(`/mails/messages/${messageId}/snippet`);
}

export function createDraft(email: SendEmail) {
  return apiClient.post(`/mails/drafts`, email);
}

// export function deleteDraft(messageId: string) {
//   return apiClient.delete(`/mails/drafts/${messageId}`);
// }

export function deleteMessage(messageId: string) {
  return apiClient.delete(`/mails/messages/${messageId}`);
}

export function updateMessageLabels({
  messageId,
  add = [],
  remove = [],
}: {
  messageId: string;
  add?: string[];
  remove?: string[];
}) {
  return apiClient.put(`/mails/messages/${messageId}/labels`, {
    messageId,
    add,
    remove,
  });
}

export function markAsRead(messageId: string) {
  return updateMessageLabels({
    messageId,
    remove: ['UNREAD'],
  });
}