import { Button, Flex, Text, useToast, Stack, Input } from "@chakra-ui/react";
import Modal from "components/Modal";
import { useState } from "react";
import { useNavigate } from "react-router";
import { deleteOrganisation } from "../api";

type DeleteOrganisationProps = {
  organisationId: string;
  name: string;
};
export default function DeleteOrganisation({
  organisationId,
  name,
}: DeleteOrganisationProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onDelete = () => {
    const promise = deleteOrganisation({ organisationId });

    promise.then(() => {
      navigate("/admin/organisations");
    });

    toast.promise(promise, {
      loading: { title: "Deleting organisation..." },
      success: { title: "Organisation deleted" },
      error: { title: "Something went wrong when deleting your organisation." },
    });
  };

  const [confirmName, setConfirmName] = useState("");

  return (
    <>
      <Button colorScheme="red" onClick={() => setIsOpen(true)} w="fit-content">
        Delete organisation
      </Button>
      <Modal
        isOpen={isOpen}
        body={
          <Stack>
            <Text>
              This will permanently delete all data associated with your
              organisation.
            </Text>
            <Text>
              Please type <b>{name}</b> to confirm that you want to delete it.
            </Text>
            <Input
              value={confirmName}
              placeholder={name}
              onChange={(e) => setConfirmName(e.target.value)}
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
            />
          </Stack>
        }
        footer={
          <Flex gap={4}>
            <Button w="fit-content" onClick={() => setIsOpen(false)}>
              No, go back
            </Button>
            <Button
              isDisabled={confirmName !== name}
              colorScheme="red"
              w="fit-content"
              onClick={onDelete}
            >
              Yes, delete my organisation
            </Button>
          </Flex>
        }
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
