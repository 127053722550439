import axios from "axios";
import Cookies from "js-cookie";
import authStore from "stores/authStore";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      authStore.logOut();
    }
    return Promise.reject(error);
  }
);

export const fetcher = (url: string) =>
  apiClient.get(url).then((res) => res.data);
