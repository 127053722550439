import {
  Stack,
  Stat,
  StatNumber,
  Tag,
  Heading,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Divider,
} from "@chakra-ui/react";
import CopyToClipboard from "components/CopyToClipboard";
import { isValidEmail, isValidHttpUrl } from "utils/util";

export function StructuredData({ data }: { data: any }) {
  if (typeof data === "number") {
    return (
      <Stat>
        <Flex gap={2} align="center">
          <StatNumber>{data.toLocaleString()}</StatNumber>
          <CopyToClipboard text={data.toString()} label="Copy" />
        </Flex>
      </Stat>
    );
  } else if (Array.isArray(data)) {
    return <ArrayData data={data} />;
  } else if (typeof data === "object" && data !== null) {
    return (
      <Accordion allowMultiple allowToggle>
        <Stack>
          {Object.entries(data).map(([field, value]: any) => (
            <Stack key={field}>
              <Field key={field} name={field} value={value} />
            </Stack>
          ))}
        </Stack>
      </Accordion>
    );
  } else if (typeof data === "string") {
    if (isValidHttpUrl(data)) {
      return (
        <Flex gap={2} align="center">
          <Link href={data}>{data}</Link>
          <CopyToClipboard text={data} label="Copy" />
        </Flex>
      );
    } else if (isValidEmail(data)) {
      return (
        <Flex gap={2} align="center">
          <Link href={`mailto:${data}`}>{data}</Link>
          <CopyToClipboard text={data} label="Copy" />
        </Flex>
      );
    }
  }

  return (
    <Flex gap={2} align="center">
      <Tag w="fit-content" fontSize={18}>
        {data}
      </Tag>
      <CopyToClipboard text={data} label="Copy" />
    </Flex>
  );
}

function Field({ name, value }: { name: string; value: any }) {
  return (
    <AccordionItem>
      <Flex align="center" gap={2}>
        <AccordionButton>
          <Heading size="md">{name}</Heading>
        </AccordionButton>
        <AccordionIcon />
        <CopyToClipboard text={JSON.stringify(value)} label="Copy" />
      </Flex>
      <AccordionPanel>
        <StructuredData data={value} />
      </AccordionPanel>
    </AccordionItem>
  );
}

function ArrayData({ data }: { data: any }) {
  return (
    <Stack>
      {data.map((item: any) => (
        <>
          <StructuredData key={item} data={item} />
          <Divider />
        </>
      ))}
    </Stack>
  );
}
