/*eslint-disable*/

import {
  Divider,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaRegCopyright } from "react-icons/fa";

export default function Footer() {
  return (
    <Stack marginY={4}>
      <Copyright />
    </Stack>
  );
}

function Copyright() {
  return (
    <Flex alignItems="center" gap={2}>
      <FaRegCopyright />
      <Text>Lab Technologies ({new Date().getFullYear()})</Text>
    </Flex>
  );
}
