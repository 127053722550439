import {
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  StepIcon,
  StepNumber,
  useSteps,
  Flex,
  Button,
  FormControl,
  Textarea,
  useToast,
  Stack,
  Checkbox,
  Text,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { MdManageSearch } from "react-icons/md";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormFooter from "components/stepper/FormFooter";
import { websiteSummary } from "../api";
import { apiClient } from "utils/api";
import FormInput from "components/stepper/FormInput";
import { useState } from "react";

// type FormInputs = {
//   title: string;
//   url: string;
//   username: string;
//   applicationPassword: string;
//   contentStyle: string;
// };

export default function OrganisationForm({ onDone }: any) {
  const schema = yup.object({
    name: yup.string().required(),
    websiteUrl: yup
      .string()
      .url("Must be a valid URL")
      .required("This field is required"),
    summary: yup.string().required("This field is required"),
    businessObjectives: yup.array().of(yup.string()),
  });
  const resolver = yupResolver(schema);
  const form = useForm({ resolver });

  const steps = [
    {
      title: "Details",
      description: "",
      element: <DetailsSection />,
      validate: ["name"],
    },
    {
      title: "Business Summary",
      description: "",
      element: <BusinessScan />,
      validate: [],
    },
    {
      title: "Purpose",
      description: "",
      element: <Purpose />,
      validate: [],
    },
  ];

  const stepperData = useSteps({
    index: 0,
    count: steps.length,
  });

  const onSubmit = form.handleSubmit((data) => {
    apiClient
      .post("/organisations", data)
      .then(() => {
        onDone();
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <Flex direction="column">
      <Stepper index={stepperData.activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <FormProvider {...form}>
        <Box marginY={4}>{steps[stepperData.activeStep].element}</Box>
        <FormFooter
          {...stepperData}
          totalSteps={steps.length}
          onSubmit={onSubmit}
          validate={steps[stepperData.activeStep].validate}
          skipText="Skip for now"
          nextText={
            steps[stepperData.activeStep]["title"] === "Business Summary"
              ? "Looks good"
              : "Next"
          }
        />
      </FormProvider>
    </Flex>
  );
}

function DetailsSection() {
  return (
    <Flex direction="column" gap={4}>
      <FormInput field="name" label="Name" placeholder="Name of organisation" />
    </Flex>
  );
}

function Purpose() {
  const form = useFormContext();
  const businessObjectives = form.watch("businessObjectives", []);
  const DEFAULT_OPTIONS = [
    "Increase Revenue",
    "Improve Customer Retention",
    "Optimise Operations",
    "Enhance Digital Marketing",
    "Boost SEO Performance",
    "Improve Blogging Strategy",
    "Optimise PPC Ads",
    "Expand Market Share",
    "Streamline Supply Chain",
    "Increase Online Visibility",
  ];
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const [other, setOther] = useState("");

  const onAdd = () => {
    setOptions((options: string[]) => [...options, other]);
    form.setValue("businessObjectives", [...businessObjectives, other]);
    setOther("");
  };

  return (
    <Stack>
      <Text>
        Please select all business objectives that apply to your organisation
      </Text>
      {options.map((option) => (
        <Checkbox
          isChecked={businessObjectives.includes(option)}
          onChange={(e) => {
            form.setValue(
              "businessObjectives",
              e.target.checked
                ? [...businessObjectives, option]
                : businessObjectives.filter((o: string) => o !== option)
            );
          }}
        >
          {option}
        </Checkbox>
      ))}

      <Flex gap={4}>
        <Input
          placeholder="Other"
          value={other}
          onChange={(e) => setOther(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onAdd();
            }
          }}
        />
        <Tooltip label="Click here or press enter">
          <Button onClick={onAdd}>Add</Button>
        </Tooltip>
      </Flex>
    </Stack>
  );
}

function BusinessScan() {
  const form = useFormContext();
  const toast = useToast();

  const websiteUrl = form.watch("websiteUrl", "");

  return (
    <Stack gap={0}>
      <FormInput
        field="websiteUrl"
        label="Website URL"
        placeholder="Link to main website"
      />
      <Button
        isDisabled={!websiteUrl}
        leftIcon={<MdManageSearch fontSize={25} />}
        onClick={() => {
          const promise = websiteSummary({
            url: websiteUrl,
          })
            .then(({ summary }) => {
              form.setValue("summary", summary, { shouldDirty: true });
            })
            .catch((err) => {
              console.log(err);
            });
          toast.promise(promise, {
            loading: { title: "Analysing website..." },
            success: { title: "Analysis successful" },
            error: { title: "Analysis failed" },
          });
        }}
        width="fit-content"
        marginY={4}
        colorScheme="blue"
      >
        Scan my business
      </Button>
      <FormInput
        field="summary"
        label="Summary"
        placeholder="Describe your website's target audience, topics of interest and the company's business"
        {...form.register("summary")}
        textarea={true}
      />
    </Stack>
  );
}
