import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Button,
  Link,
  Heading,
  SimpleGrid,
  Icon,
  Image,
  Stack,
} from "@chakra-ui/react";
import { FaRocket, FaCogs, FaShieldAlt } from "react-icons/fa";

export default function LandingPage() {
  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Navbar />
        <Stack spacing={8} align="center" w="100%">
          <Hero />
          <About />
          <Features />
          <Pricing />
          <Contact />
        </Stack>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

function Navbar() {
  return (
    <Box bgColor="white" px={4} py={2} color="black">
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Flex align="center" gap={2}>
          <Image src="/blisss-logo.png" height="50" borderRadius="50%" />
          <Text fontSize="xl" fontWeight="bold">
            {process.env.REACT_APP_APP_NAME}
          </Text>
        </Flex>
        <Flex alignItems="center" flexWrap="wrap">
          <Link href="#about" px={3}>
            About
          </Link>
          <Link href="#features" px={3}>
            Features
          </Link>
          <Link href="#pricing" px={3}>
            Pricing
          </Link>
          <Link href="#contact" px={3}>
            Contact
          </Link>
          <Link href="/privacy-policy" px={3}>
            Privacy Policy
          </Link>
          <Link href="/sign-in" px={3}>
            <Button bg="gray.500" color="white" ml={4}>
              Sign In
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}

function Hero() {
  return (
    <Box bg="#0079f1" py={20} px={8} width="100%">
      <Flex width="75%" margin="auto" align="center" justify="space-around">
        <Stack color="white">
          <Heading as="h1" size="2xl" mb={4}>
            Welcome to {process.env.REACT_APP_APP_NAME}
          </Heading>
          <Text fontSize="xl" mb={4}>
            Create amazing content and share it with the world!
          </Text>
          <Link href="/sign-up">
            <Button size="lg" colorScheme="red">
              Get Started
            </Button>
          </Link>
        </Stack>
      </Flex>
    </Box>
  );
}

function Features() {
  return (
    <Stack id="features" py={20} px={8}>
      <Heading as="h2" size="xl" mb={8}>
        Features
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          title="Intuitive UI"
          text="Easy-to-use interface."
          icon={FaRocket}
        />
        <Feature
          title="Highly Customizable"
          text="Tailor it to your needs."
          icon={FaCogs}
        />
        <Feature
          title="Secure"
          text="Top-notch security features."
          icon={FaShieldAlt}
        />
      </SimpleGrid>
      <Link href={process.env.REACT_APP_FEEDBACK_BOARD_URL}>
        Request features
      </Link>
    </Stack>
  );
}

function Pricing() {
  return (
    <Box id="pricing" py={20} px={8}>
      <Heading as="h2" size="xl" mb={8}>
        Pricing
      </Heading>
      Free for select users.
    </Box>
  );
}

type FeatureProps = {
  title: string;
  text: string;
  icon: any;
};
function Feature({ title, text, icon }: FeatureProps) {
  return (
    <Box textAlign="center" p={5} borderWidth={1} borderRadius="md">
      <Icon as={icon} w={10} h={10} mb={4} color="red" />
      <Heading as="h3" size="lg" mb={2}>
        {title}
      </Heading>
      <Text>{text}</Text>
    </Box>
  );
}

function Contact() {
  return (
    <Box id="contact" py={20} px={8}>
      <Heading as="h2" size="xl" mb={8}>
        Contact
      </Heading>
      <Text>Email: rohan@examineme.co.uk</Text>
    </Box>
  );
}

function About() {
  return (
    <Box id="about" py={20} px={8}>
      <Heading as="h2" size="xl" mb={8}>
        About
      </Heading>
      <Text>
        Generate blogs, social media posts and emails even faster using AI.
        Integrate with your existing WordPress sites and Gmail account.
      </Text>
    </Box>
  );
}

function Footer() {
  return (
    <Box bg="blue.500" color="white" py={4}>
      <Text>
        &copy; {new Date().getFullYear()} Lab Technologies. All rights reserved.
      </Text>
    </Box>
  );
}
