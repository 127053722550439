import React from "react";

// chakra imports
import {
  Box,
  Drawer,
  DrawerBody,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";

// Assets
import { IoMenuOutline } from "react-icons/io5";

function Sidebar(props: { routes: any[]; [x: string]: any }) {
  const { routes } = props;
  let variantChange = "0.2s linear";

  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("navy.800", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <>
      <Box
        // position="fixed"
        minH="100vh"
        bg={sidebarBg}
        transition={variantChange}
        w="fit-content"
        h="100vh"
        m={sidebarMargins}
        boxShadow={shadow}
        position="sticky"
        top={0}
      >
        <Box
          w="fit-content"
          px={4}
          h="100%"
          display={{ sm: "none", xl: "block" }}
        >
          <Content routes={routes} />
        </Box>
        <Box h="100%" minH="100vh" display={{ sm: "block", xl: "none" }}>
          <SidebarResponsive routes={routes} />
        </Box>
      </Box>
    </>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: any[] }) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Box
      bgColor={sidebarBackgroundColor}
      minH="100vh"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      w="fit-content"
    >
      <IconButton
        aria-label="Menu"
        onClick={onOpen}
        ref={btnRef}
        icon={<IoMenuOutline />}
        color={menuColor}
        fontSize={20}
        m={2}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
        styleConfig={{ width: "fit-content" }}
      >
        <DrawerOverlay />
        <DrawerContent w="fit-content" maxW="285px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            onClick={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody w="fit-content" maxW="285px" px="0rem" pb="0">
            <Content routes={routes} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
// PROPS

export default Sidebar;
