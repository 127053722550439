import {
  Heading,
  Stack,
  Link,
  Divider,
  Text,
  Card,
  CardBody,
  SimpleGrid,
  Icon,
  Image,
} from "@chakra-ui/react";
import {
  FaLinkedin,
  FaXTwitter,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa6";

const platforms = [
  {
    name: "X (formerly Twitter)",
    icon: <Icon as={FaXTwitter} fontSize={50} />,
    path: "connections/x",
    description:
      "Businesses using X can benefit from real-time engagement and communication with a broad audience, allowing for immediate interaction, customer feedback, and viral marketing opportunities.",
  },
  {
    name: "LinkedIn",
    icon: <Icon as={FaLinkedin} color="#0a66c2" fontSize={50} />,
    path: "connections/linkedin",
    description:
      "LinkedIn offers businesses a platform for professional networking, B2B marketing, and talent acquisition, facilitating connections with industry professionals, thought leaders, and potential clients.",
  },
  {
    name: "Facebook",
    icon: <FaFacebook fontSize={50} color="#1877f2" />,
    path: "connections/facebook",
    description:
      "Businesses using Facebook can leverage its extensive user base for targeted advertising, community building, and customer engagement, enabling personalized marketing strategies and fostering brand loyalty through direct interaction and content sharing.",
  },
  {
    name: "Instagram",
    icon: <Image src="/Instagram_logo_2016.png" w={50} h={50} />,
    path: "connections/instagram",
    description:
      "Using Instagram for business offers significant advantages, including the ability to showcase products and services through visually engaging content, high engagement rates with an active user base, and the opportunity to build brand awareness and loyalty.",
  },
];
export default function Connections() {
  return (
    <Stack gap={4} id="connections">
      <Heading>Connections</Heading>
      <Divider />
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
        {platforms.map((platform) => (
          <PlatformDetails key={platform.name} {...platform} />
        ))}
      </SimpleGrid>
    </Stack>
  );
}

type PlatformDetailsProps = {
  name: string;
  icon: JSX.Element;
  path: string;
  description?: string;
};
function PlatformDetails({
  name,
  icon,
  path,
  description = "",
}: PlatformDetailsProps) {
  return (
    <Link href={path}>
      <Card
        border="1px solid lightgray"
        _hover={{
          backgroundColor: "#f0f0f0",
          transition: "0.5s",
          transform: "scale(0.99)",
        }}
        height="100%"
        transition="0.5s"
      >
        <CardBody>
          <Stack>
            <Stack gap={4}>
              {icon}
              <Heading size="md">{name}</Heading>
            </Stack>
            <Text color="gray">{description}</Text>
          </Stack>
        </CardBody>
      </Card>
    </Link>
  );
}
