import { Flex, Text, Button } from "@chakra-ui/react";
import {
  FaImage,
  FaFile,
  FaFileWord,
  FaFileExcel,
  FaFileCsv,
  FaFilePdf,
  FaFilePowerpoint,
} from "react-icons/fa6";
import { LuFileJson } from "react-icons/lu";
import { BiSolidFileTxt } from "react-icons/bi";
import { Attachment } from "types";

type AttachmentProps = {
  attachments: Attachment[];
};
export default function Attachments({ attachments }: AttachmentProps) {
  const icons: { [key: string]: JSX.Element } = {
    png: <FaImage />,
    jpg: <FaImage />,
    jpeg: <FaImage />,
    doc: <FaFileWord />,
    docx: <FaFileWord />,
    xls: <FaFileExcel />,
    xlsx: <FaFileExcel />,
    csv: <FaFileCsv />,
    pdf: <FaFilePdf />,
    json: <LuFileJson />,
    txt: <BiSolidFileTxt />,
    ppt: <FaFilePowerpoint />,
    pptx: <FaFilePowerpoint />,
  };

  const handleDownload = (attachment: Attachment) => {
    const link = document.createElement("a");
    link.href = `data:application/octet-stream;base64,${attachment.data}`;
    link.download = attachment.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex gap={4}>
      {attachments.map(({ fileName, data }: Attachment) => (
        <Button
          onClick={() => handleDownload({ fileName, data })}
          leftIcon={
            icons[fileName.split(".").pop().toLocaleLowerCase()] ?? <FaFile />
          }
        >
          <Text>{fileName}</Text>
        </Button>
      ))}
    </Flex>
  );
}
