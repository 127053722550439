import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useDisclosure,
  Button,
  Stack,
  FormControl,
  FormLabel,
  useToast,
  Box,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import ReactDiffViewer from "react-diff-viewer";
import { rephraseContent } from "../api";
import { BsStars } from "react-icons/bs";

type RephraseContentProps = {
  title?: string;
  content: string;
  onUpdate: (newContent: string) => void;
  tooltip?: string;
};
export default function RephraseContent({
  title = "Rephrase content",
  content,
  onUpdate,
  tooltip = null,
}: RephraseContentProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState("");
  const [newContent, setNewContent] = useState(null);

  useEffect(() => {
    setNewContent(null);
  }, [content]);

  const onSubmit = () => {
    const promise = rephraseContent({ content, comment }).then((res) => {
      const { content } = res;
      setNewContent(content);
    });
    toast.promise(promise, {
      loading: { title: "Rephrasing content..." },
      success: { title: "Content rephrased" },
      error: {
        title: "Something went wrong when rephrasing the content.",
      },
    });
  };

  const onApprove = () => {
    onUpdate(newContent);
    toast({
      title: "Content updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <Box>
      <Tooltip label={tooltip}>
        <Button
          leftIcon={<BsStars />}
          width="fit-content"
          onClick={onOpen}
          disabled={!content}
        >
          {title}
        </Button>
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={newContent ? "full" : "lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={2}>
              <FormControl>
                <FormLabel>Instructions</FormLabel>
                <Textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="What needs to be changed in the content"
                />
              </FormControl>
              <Flex>
                <Button onClick={onSubmit}>Submit</Button>
              </Flex>
              {newContent && (
                <ReactDiffViewer
                  oldValue={content}
                  newValue={newContent}
                  splitView={true}
                />
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button isDisabled={!newContent} onClick={onApprove}>
              Approve changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
